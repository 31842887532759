<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1 && control.synopticComponent">
      <ControlBaseProperties :value="control" @input="updateBaseProperties" />

      <ControlSpecificPanel :control="control">
        <DataFormatInput
          :value="control.format"
          @input="updateFormat($event)"
          :control="control"
        />
        <TogglePanel
          title="limits"
          class="toggle-panel"
          :collapsed="true"
          style="margin-bottom: 50px"
        >
          <div>
            <div class="form-group form-group-sm">
              {{ $t("multiple_levels") }}
              <label for="opt_no" class="radio-label clicable no-select">
                <input
                  type="radio"
                  id="opt_no"
                  :value="false"
                  v-model="isMultiLimit"
                />
                {{ $t("no") }}
              </label>

              <label for="opt_yes" class="radio-label clicable no-select">
                <input
                  type="radio"
                  id="opt_yes"
                  :value="true"
                  v-model="isMultiLimit"
                />
                {{ $t("yes") }}
              </label>
            </div>
          </div>
          <MinMaxForm v-model="minmax" />
          <div style="margin: 10px 5px 0 10px">
            <TogglePanel
              v-if="isMultiLimit"
              :title="$tc('level', 2)"
              :collapsed="true"
            >
              <div>
                <div class="limit-box form-group form-group-sm">
                  <div class="input-group">
                    <div class="input-group-addon">
                      <span class="text-primary text-bold">{{
                        $tc("value", 1)
                      }}</span>
                    </div>
                    <select
                      class="form-control form-group-sm no-padding"
                      v-model="intervalSource"
                    >
                      <option value="constant">{{ $t("constant") }}</option>
                      <option value="percentage">{{ $t("percentage") }}</option>
                      <option value="data">{{ $t("from_data") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-sm limit-box">
                <div class="input-group">
                  <div class="input-group-addon">
                    <span
                      class="text-primary text-bold clicable no-select"
                      @click.stop.prevent="toggleColorDir"
                    >
                      <i
                        :class="
                          colorDir
                            ? 'fa fa-long-arrow-down'
                            : 'fa fa-long-arrow-up'
                        "
                      ></i>
                      {{ $tc("color_pattern", 1) }}</span
                    >
                  </div>
                  <select
                    class="form-control form-group-sm no-padding"
                    v-model="control.synopticComponent.fill"
                  >
                    <option value="single">{{ $tc("single", 1) }}</option>
                    <option value="gradient">{{ $tc("gradient", 1) }}</option>
                    <option value="piled">{{ $tc("piled", 1) }}</option>
                  </select>
                </div>
              </div>

              <div
                v-for="(item, ix) in control.synopticComponent.interval.items"
                :key="ix"
              >
                <div
                  class="form-group form-group-sm"
                  style="margin-bottom: 1px"
                >
                  <div class="input-group">
                    <div class="input-group-addon btn no-padding">
                      <ColorPicker
                        class="color-picker"
                        :value="item.color"
                        :pickerStyle="{left: '0px'}"
                        @input="setColor(ix, $event)"
                      />
                    </div>
                    <div
                      class="input-group-addon btn"
                      @click.stop.prevent="toggleMarkerItem(ix)"
                    >
                      <span
                        :style="{
                          color: isColorMarkerEnabled(ix) ? '#333' : '#999'
                        }"
                      >
                        <i
                          class="fa fa-pencil"
                          v-if="visibleMarkerItems[ix]"
                        ></i>
                        <i class="fa fa-info-circle clicable" v-else></i>
                      </span>
                    </div>
                    <div
                      class="form-control text-center clicable no-select"
                      v-if="intervalSource == 'data'"
                      @click.stop.prevent="toggleDataItem(ix)"
                    >
                      {{ $tc("data", 1) }}
                      <div class="pull-right">
                        <i
                          class="fa fa-angle-up"
                          v-if="visibleDataItems[ix]"
                        ></i>
                        <i class="fa fa-angle-down" v-else></i>
                      </div>
                    </div>
                    <input
                      v-else
                      class="form-control text-center"
                      type="number"
                      @input="setColorSize(ix, $event.target.value)"
                      :value="getColorSize(ix).value"
                      :title="$t(hasColorError(ix) ? 'out_of_range' : 'value')"
                      :class="{
                        'has-error': hasColorError(ix)
                      }"
                    />
                    <div
                      class="input-group-addon btn"
                      @click.stop.prevent="delColor(ix)"
                    >
                      <i class="fa fa-trash"></i>
                    </div>
                  </div>
                </div>
                <div
                  v-if="intervalSource == 'data' && visibleDataItems[ix]"
                  class="interval-form"
                >
                  <ValueSourceForm
                    :value="interval(ix)"
                    @input="interval(ix, $event)"
                    :typeList="['data']"
                  />
                </div>
                <div v-if="visibleMarkerItems[ix]">
                  <ProgressBarMarker :marker="item.marker" :control="control" />
                </div>
              </div>
              <div class="bottom-toolbar">
                <div v-if="showAddColor">
                  <ColorPicker
                    class="color-picker"
                    icon="fa fa-plus"
                    @input="setColor(-1, $event)"
                    :pickerStyle="{left: '0px'}"
                  />
                </div>
                <div v-if="intervalSource == 'constant'">
                  <span
                    class="btn btn-default btn-xs button-import-alarm"
                    @click.stop.prevent="importFromAlarm"
                    :disabled="importing"
                  >
                    <i class="fa fa-refresh fa-spin" v-if="importing"></i>
                    <i class="fa fa-bell" v-else></i>
                    <span style="padding: 0 5px"
                      >{{ $t("import_from_alarm") }}
                    </span>
                  </span>
                </div>
              </div>
            </TogglePanel>
            <TogglePanel v-else :title="$tc('color', 1)" :collapsed="true">
              <ColorPicker
                style="display: inline-block"
                class="color-picker"
                :pickerStyle="{left: '-200px'}"
                v-model="control.synopticComponent.barColor"
              />
              <span>
                {{ $tc("bar_color", 1) }}
              </span>
            </TogglePanel>
          </div>
        </TogglePanel>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import ProgressBarMarker from "@/components/synoptic/property-editor/controls/progress-bar-marker.vue";
import ColorPicker from "@/components/editor/color-picker";
import AlarmService from "@/services/alarm.js";
import DataFormatInput from "@/components/synoptic/property-editor/controls/data-format-input.vue";
import MinMaxForm from "@/components/synoptic/property-editor/controls/minmax-form.vue";
import Controls from "@/assets/dashboard/controls.json";
import ValueSourceForm from "@/components/synoptic/property-editor/controls/value-source-form.vue";
const defaultItervalItem = function () {
  let control = Controls.find(
    (item) =>
      item.template.synopticComponent.componentName == "SynopticProgressBar"
  );
  let item = control
    ? control.template.synopticComponent.interval.items[0]
    : null;
  return JSON.parse(JSON.stringify(item));
};

const colorList = [
  "#4b94bf",
  "#f39c12",
  "#00c0ef",
  "#00a65a",
  "#dd4b39",
  "#828282",
  "#cda819",
  "#9966ff",
  "#4b94bf",
  "#f39c12",
  "#00c0ef",
  "#00a65a"
];
export default {
  name: "ProgressBar",
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    TogglePanel,
    ControlSpecificPanel,
    ColorPicker,
    ProgressBarMarker,
    DataFormatInput,
    MinMaxForm,
    ValueSourceForm
  },
  data() {
    return {
      control: {},
      skipUpdate: false,
      merge: true,
      showAddColor: true,
      colorSizeError: false,
      visibleDataItems: [],
      visibleMarkerItems: [],
      importing: false,
      colorDir: false
    };
  },
  computed: {
    minmax: {
      set(value) {
        this.$set(this.control.synopticComponent, "min", value.min);
        this.$set(this.control.synopticComponent, "max", value.max);
      },
      get() {
        return {
          min: this.control.synopticComponent.min,
          max: this.control.synopticComponent.max
        };
      }
    },
    isMultiLimit: {
      set(value) {
        this.$set(
          this.control.synopticComponent,
          "fill",
          value ? "single" : "none"
        );
      },
      get() {
        return this?.control?.synopticComponent?.fill != "none";
      }
    },
    vertical: {
      set(value) {
        if (value != this?.control.synopticComponent.vertical) {
          this.control.synopticComponent.vertical = value;
          let r = JSON.parse(
            JSON.stringify(this.control.synopticComponent.clientRect)
          );

          let w = r.width;
          let h = r.height;

          if (value) {
            r.left = r.left + (Math.round(w / 2) - Math.round(h / 2));
            r.top = r.top - Math.round(w / 2) + Math.round(h / 2);
          } else {
            r.left = r.left - Math.round(h / 2) + Math.round(w / 2);
            r.top = r.top + Math.round(h / 2) - Math.round(w / 2);
          }
          r.width = h;
          r.height = w;
          this.$set(this.control.synopticComponent, "clientRect", r);
        }
      },
      get() {
        return this?.control?.synopticComponent?.vertical || false;
      }
    },
    intervalSource: {
      set(value) {
        let interval = this?.control?.synopticComponent?.interval || {};
        interval.source = value;
        if (value !== "data") {
          // clean up
          (interval.items || []).forEach((item) => {
            item.data_id = "";
          });
        }
        this.$set(this.control.synopticComponent, "interval", interval);
      },
      get() {
        return (
          this?.control?.synopticComponent?.interval?.source || "percentage"
        );
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: this.control,
          noMerge: !this.merge
        });
        this.merge = true;
      }
    },
    controls: {
      deep: true,
      handler(val) {
        if (!this.skipUpdate) {
          let n = JSON.parse(JSON.stringify(val[0]));
          let o = JSON.parse(JSON.stringify(this.control));
          if (!isEqual(n, o)) {
            this.control = JSON.parse(JSON.stringify(n));
          }
        }
        this.skipUpdate = false;
      }
    }
  },
  methods: {
    updateFormat(value) {
      this.skipUpdate = true;
      this.control.format = value;
    },
    setColor(ix, value) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        interval.items[ix].color = value;
        this.$set(this.control.synopticComponent, "interval", interval);
      } else if (ix < 0) {
        this.showAddColor = false;
        let item = defaultItervalItem();
        item.color = value;
        if (this?.control?.synopticComponent?.interval?.source == "constant") {
          item.value = 0;
        }
        interval.items.push(item);
        this.$set(this.control.synopticComponent, "interval", interval);
        this.$nextTick(() => {
          this.showAddColor = true;
        });
        if (
          this?.control?.synopticComponent?.interval?.source == "percentage"
        ) {
          this.resizeColors();
        }
      }
    },
    setMarker(ix, entry) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        interval.items[ix].marker = entry;
        this.$set(this.control.synopticComponent, "interval", interval);
      }
    },
    delColor(ix) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        this.merge = false;
        interval.items.splice(ix, 1);
        this.$set(this.control.synopticComponent, "interval", interval);
      }
      this.resizeColors();
    },
    sortInterval() {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      interval.items.sort((a, b) => {
        if (parseFloat(a.value) > parseFloat(b.value)) return 1;
        if (parseFloat(b.value) > parseFloat(a.value)) return -1;
        return 0;
      });
      this.$set(this.control.synopticComponent, "interval", interval);
    },
    resizeColors() {
      if (this?.control?.synopticComponent?.interval?.source != "percentage") {
        this.sortInterval();
        return;
      }
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      let len = (interval.items || []).length;
      if (len) {
        let p = len ? Math.round(100 / len) : 0;
        let s = 0;
        interval.items.forEach((item) => {
          s += p;
          item.value = s;
        });
        interval.items[len - 1].value = 100;
      }
      this.$set(this.control.synopticComponent, "interval", interval);
    },
    hasColorError(ix) {
      let vlr = parseFloat(this.getColorSize(ix).value);
      if (this?.control?.synopticComponent?.interval?.source == "percentage") {
        return vlr < 0 || vlr > 100;
      } else if (
        this?.control?.synopticComponent?.interval?.source == "constant"
      ) {
        return (
          vlr < parseFloat(this.control.synopticComponent.min.value) ||
          vlr > parseFloat(this.control.synopticComponent.max.value)
        );
      }
    },
    getColorSize(ix) {
      // let colorSize = this?.control?.synopticComponent?.colorSize || [];
      // if (ix >= 0 && ix < colorSize.length) {
      //   return colorSize[ix];
      // }
      // return "";
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        return interval.items[ix];
      }
      return "";
    },
    setColorSize(ix, value) {
      if (value === "") return;
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      this.colorSizeError = false;
      if (this?.control?.synopticComponent?.interval?.source == "percentage") {
        interval.items[ix].value = parseInt(value);
        interval.items[interval.items.length - 1].value = 100;
      } else if (
        this?.control?.synopticComponent?.interval?.source == "constant"
      ) {
        let min = this.control.synopticComponent.min;
        let max = this.control.synopticComponent.max;
        if (!min.data_id && value < parseFloat(min.value)) {
          this.colorSizeError = true;
        } else if (!max.data_id && value > parseFloat(max.value)) {
          this.colorSizeError = true;
        }
        interval.items[ix].value = value;
      }
      this.$set(this.control.synopticComponent, "interval", interval);
    },
    toggleColorMarker(ix) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        interval.items[ix].marker.enabled = !interval.items[ix].marker.enabled;
        this.$set(this.control.synopticComponent, "interval", interval);
      }
    },
    toggleDataItem(ix) {
      let items = JSON.parse(JSON.stringify(this.visibleDataItems));
      items[ix] = items[ix] ? false : true;
      this.$set(this, "visibleDataItems", items);
    },
    toggleMarkerItem(ix) {
      let items = JSON.parse(JSON.stringify(this.visibleMarkerItems));
      items[ix] = items[ix] ? false : true;
      this.$set(this, "visibleMarkerItems", items);
    },
    isColorMarkerEnabled(ix) {
      let interval = this?.control?.synopticComponent?.interval || {
        items: []
      };
      if (ix >= 0 && ix < interval.items.length) {
        return interval?.items[ix]?.marker?.enabled;
      }
      return false;
    },
    updateItemDataId(ix, value) {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (ix >= 0 && ix < interval.items.length) {
        interval.items[ix].data_id = value || "";
        this.$set(this.control.synopticComponent, "interval", interval);
      }
    },
    interval(ix, value) {
      if (ix === undefined) return null;
      let entry = null;
      if (value !== undefined) {
        entry = this?.control?.synopticComponent?.interval || {items: []};
        if (ix >= 0 && ix < entry.items.length) {
          this.$set(entry.items, ix, {...entry.items[ix], ...(value || {})});
          this.$set(this.control.synopticComponent, "interval", entry);
        }
      } else {
        entry = this?.control?.synopticComponent?.interval || {items: []};
      }
      return (entry?.items || [])[ix];
    },
    updateControl(value) {
      this.$store.dispatch("synoptic/updateControl", value);
    },
    importFromAlarm() {
      if (!this?.control?.data_id) return;
      let srv = new AlarmService();
      let query = {
        contract_id: this.$store.getters["user/contract"].id,
        data_id: this.control.data_id
      };
      this.importing = true;
      srv.fetch(query).then((resp) => {
        this.importing = false;
        if (resp && resp.length) {
          // trigger condition: 1(>) 2(>=)
          let values = {};
          let items = resp
            .filter((alarm) => {
              let r =
                (alarm.alarm_trigger_condition.id == 1 ||
                  alarm.alarm_trigger_condition.id == 2) &&
                !(alarm.limit in values);
              if (r) values[alarm.limit] = true;
              return r;
            })
            .sort((a, b) => a.limit - b.limit)
            .map((alarm, ix) => {
              let item = defaultItervalItem();
              item.value = alarm.limit;
              if (alarm.alarm_trigger_condition.id == 1) item.value - 0.01; // >
              item.color = colorList[ix] || "#000";
              item.marker.enabled = true;
              return item;
            });
          if (items.length) {
            this.$set(this.control.synopticComponent.interval, "items", items);
          }
        }
      });
    },
    toggleColorDir() {
      let interval = JSON.parse(
        JSON.stringify(
          this?.control?.synopticComponent?.interval || {items: []}
        )
      );
      if (interval.items.length) {
        this.colorDir = !this.colorDir;
        interval.items
          .map(({color}) => color)
          .reverse()
          .forEach((color, ix) => {
            interval.items[ix].color = color;
          });
        this.$set(this.control.synopticComponent, "interval", interval);
      }
    },
    updateBaseProperties(control) {
      this.merge = false;
      this.control = control;
    }
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
    this.sortInterval();
  }
};
</script>

<style scoped>
.toggle-panel {
  margin-bottom: 0;
}

.limit-box {
  margin: 0;
}

.multi-color-selector {
  margin-bottom: 0.5em;
}

.color-item {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  max-width: 28px;
}

.add-button {
  margin-top: 22px;
}

.color-size {
  width: 100%;
  text-align: left;
  padding: 0 0 0 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  font-size: 80%;
}
.color-size-error {
  border-color: red;
}
.color-size:focus {
  outline-width: 0;
}

.color-size-perc {
  position: absolute;
  right: 2px;
  top: 6px;
  font-size: 60%;
}

.constant {
  width: 70px;
  height: 28px !important;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: center;
}

input[type="number"] {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.bottom-toolbar {
  margin: 15px 5px;
}

.bottom-toolbar > div {
  display: inline-block;
}

.button-import-alarm {
  padding: 3px 5px;
  margin-left: 5px;
}

.field-block {
  padding: 5px;
  text-align: center;
  background: whitesmoke;
  margin: 0 -2px;
}

input[type="radio"] {
  margin-left: 15px;
  margin-right: 5px;
}

.label-addon {
  width: 74px;
}

input.has-error {
  color: #aa0101;
  background-color: rgb(253, 236, 236);
}
.interval-form {
  margin-top: -5px;
  margin-bottom: 10px;
  border-radius: 4px;

  padding: 5px 5px 30px 0px;
  border: 1px solid #dadada;
}
</style>
